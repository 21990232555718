<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title> ... 🍹</title>

<link rel="icon" sizes="any" href="/favicon.png">

<style>/* core styles */</style>

<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto Sans Math:100,400,800">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto Sans:100,200,300,400,500,600,700,800,900">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto Emoji">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto Sans Symbols">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto Sans Symbols 2">


<script>/* core scripts */</script>


</head>
<body>

<!-- init html -->

</body>
</html>
{
  "name": "tty.wtf",
  "version": "0.12.15",
  "description": "Type To Yourself ",
  "private": true,
  "main": "index.js",
  "scripts": {
    "start": "node build --serve",
    "build": "node build",
    "start-old": "esbuild src/index.js --target=es6 --bundle --sourcemap --outfile=./index.js --loader:.js=jsx --format=iife --external:fs --external:path --external:child_process --external:ws --watch --serve --servedir=. --serve-fallback=index.html",
    "build-old": "esbuild src/index.js --target=es6 --bundle --sourcemap --outfile=./index.js --loader:.js=jsx --format=iife --external:fs --external:path --external:child_process --external:ws",
    "test": "echo \"Error: no test specified\" && exit 1"
  },
  "keywords": [
    "unicode",
    "editor",
    "format",
    "javascript"
  ],
  "author": "Oleg Mihailik",
  "license": "MIT",
  "dependencies": {
    "@babel/runtime": "^7.25.4",
    "@milkdown/crepe": "^7.5.6",
    "@milkdown/plugin-math": "^7.5.0",
    "@types/node": "^22.5.1",
    "esbuild": "^0.23.1",
    "typescript": "^5.5.4"
  }
}
